import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

const DeliveryAndHookupService = () => {
  return (
    <Layout>
      <SEO
        title="Delivery and Hook-up Service"
        description="Delivery and Hook-up service terms"
        meta={[
          {
            name: 'keywords',
            content:
              'Nail salon design,Nail salon startup cost,Pedicure spa Massage chair,Contego,Contego spa,Contego spa designs,Contego spa designs inc,Contego spa liner,Contego spa chair review,Contego spa reviews,Contego spa pedicure,Contego spa pedicure chair,Contego pedicure chairs price,Pedicure chair,Pedicure spa,Spa chair,Design ideas for nail salon,Luxury pedicure chairs,Cheap pedicure chairs,Pedicure spa Massage chairs for sale,Cheap spa pedicure chairs,pedicure spa Massage chair package deal,Contego pedicure chairs,Pedicure chairs,Pedicure chairs for sale,Pedicure chairs with massage,Pedicure chairs cheap,Pedicure chairs parts,Pedicure chairs wholesale,Pedicure chair ideas,Pedicure chair for sale,Spa pedicure chairs,Nail salon pedicure chair,Nail Salon Furniture,Nail Salon Equipment,Pedi spa chair,Spa pedicure chairs,Salon Equipment,Pedicure chair parts,Pedicure furniture'
          }
        ]}
      />

      <div className="container py-4">
        <div className="row py-5">
          <div className="col">
            <h1 className="w-100 text-center">DELIVERY AND HOOK-UP SERVICE</h1>
          </div>
        </div>

        <div className="row flex-column py-2 mb-2">
          <div className="col">
            <p>
              This section supplements the Purchase Terms and Conditions of CONTÉGO Spa Designs,
              Inc. (“Contego”, “we”, “us” or “our”) (displayed at{' '}
              <a
                href="https://www.contegospa.com/purchasetermsandconditions"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.contegospa.com/purchasetermsandconditions
              </a>{' '}
              and incorporated herein) and sets forth the terms and conditions of our Delivery and
              Hook-Up Service. If you purchased our pedicure spa chair(s), and we deliver them, you
              agree to these Delivery and Hook-Up Service terms and conditions and to be bound by
              them.
            </p>

            <p>
              As part of our Delivery and Hook-Up Service, on the agreed-to Requested Ship Date, we
              will deliver your purchased pedicure spa chair(s) (“Product”) to the salon at Ship-To
              address (the “Premises”) stated on the purchase order, and we will connect them to
              water and electricity inside the Premises at the location you specify (the “Hook-Up
              Site”) on condition that the Premises and Hook-Up Site are Hook-Up and Test Ready.
              Hook-Up and Test Ready means:
            </p>

            <ol>
              <li>
                <p>You or your representative are present at the Premises</p>
              </li>
              <li>
                <p>The Premises is closed to customers;</p>
              </li>
              <li>
                <p>
                  The Premises is unlocked and the pathway to the Hook-Up Site is free of hazards
                  and obstructions;
                </p>
              </li>
              <li>
                <p>
                  All construction, plumbing and electrical work at the Premises and Hook-Up Site
                  that is required to connect and test the Product is complete and complies with all
                  applicable local building codes. This means the Hook-Up Site for each spa chair
                  includes, without limitation, the hot and cold water pipes, waste drainage
                  openings and electrical outlets comply with the specifications contained in the
                  Product’s operating manual (which is accessible via{' '}
                  <a
                    href="https://www.contegospa.com/manuals-and-downloads"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.contegospa.com/manuals-and-downloads
                  </a>
                  );
                </p>
              </li>
              <li>
                <p>
                  The Premises is free of hazardous chemicals and other airborne particulates which
                  may be harmful to our Technician;
                </p>
              </li>
              <li>
                <p>
                  You have removed from the Hook-Up Site all pedicure spa chairs being replaced by
                  your Product; and
                </p>
              </li>
            </ol>

            <p>
              Our Technician must confirm 1 through 6, above, before commencing hook-up service.
            </p>

            <h3>TESTING</h3>
            <p>
              When the Technician completes the hook-up service, the Technician must test your
              Product to make certain, and confirm, that the plumbing and electrical are working in
              compliance with the appropriate Product Specifications.
            </p>

            <h3>COSMETIC & FUNCTIONAL INSPECTION</h3>
            <p>
              Our Technician will also inspect the Product to identify any massage or cosmetic
              deficiencies. Any deficiencies discovered during the testing will be noted on the
              Hook-Up and Testing Completion Form and photographed and/or recorded, and our
              Technician will attempt to correct them prior to departing the Premises. If a return
              visit is required to fix any such deficiency, we will send a technician at no charge
              at a date and time scheduled by Customer with our sales representative for corrective
              services by means of a Contego Service Ticket.
            </p>

            <h3>CANCELLATIONS AND DELAYS</h3>

            <p>
              You hereby represent that the Premises and Hook-Up Site are Hook-Up and Test Ready
              unless you cancel the Delivery and Hook-Up Service no later than twenty-four (24)
              hours prior to the scheduled delivery date and time stated on your purchase order by
              contacting and confirming with us of the cancellation. You agree to pay a Late
              Cancellation Fee in the amount of $150.00 USD if you contact us to cancel the
              scheduled Delivery and Hook-Up Service later than twenty-four (24) hours prior to the
              scheduled delivery date and time.
            </p>

            <p>
              You agree to pay a Delivery Delay Fee in the amount of $100.00 USD if (a) the Premises
              and Hook-Up Site are <u>not</u> Hook-Up and Test Ready on the agreed-to Requested Ship
              Date (unless you timely cancelled).
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default DeliveryAndHookupService
